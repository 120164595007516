body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Colors -> Inicio */
:root {
  --primary_color: #00aa5d;
}
/* Colors -> Fin */

/* Genericos */
.centrar{
  display: flex;
  justify-content: center;
}
.btn-allergien{
  background-color: var(--primary_color) !important;
  border-color: var(--primary_color) !important;
}
/* Genericos */

/* navbar -> Inicio */
.allergien-logo-container{
  display: flex;
  align-items: center;
}
.allergien-logo-img{
  margin-left: 10px;
  margin-right: 10px;
}
.allergien-logo-text{
  color: var(--primary_color);
}
/* nabar -> Fin */

/* main container */
.main-container{
  min-height: calc(100vh - 56px);
}
/* main container */

/* footer -> Inicio */
.allergien-bg-green{
  background-color: var(--primary_color);
}
.allergien-footer-text{
  color: #FFF;
}
.allergien-logo-footer{
  background-image: url('../public/img/LogoFooter.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  width: 100%;
  height: 40px;
}
.allergien-social-item{
  margin-right: 5px;
}
.footer-anchord{
  text-decoration: none !important;
}
/* footer -> Fin */

/* Index */
.allergien-index-main-container{
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.allergien-index-sub-container{
  width: 40%;
}
.allergien-index-logo{
  background-image: url('../public/img/Logo_svg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 30vh;
}
.allergien-index-name{
  font-size: 3em;
  font-weight: bold;
  text-align: center;
}
.allergien-index-text-1{
  font-size: 2em;
  text-align: center;
}
.allergien-index-text-2{
  font-size: 1em;
  text-align: center;
}
.allergien-index-text-3{
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
/* Index */

/* Condiciones-politicas -> Inicio */
.condiciones-politicas-slider-container{
  background-image: url('../public/img/Slider.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 3em;
  font-weight: bold;
  text-align: center;
}
.condiciones-politicas-text{
  text-align: justify;
}
.condiciones-politicas-header{
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.condiciones-politicas-main-text{
  color: black;
  font-size: 18px;
  font-weight: 400;
}
.condiciones-politicas-list{
  color: black;
  font-size: 16px;
  font-weight: 400;
}
.condiciones-politicas-list-no-marker{
  list-style-type: none;
}
/* Condiciones-politicas -> Fin */
